import { useCallback, useState } from 'react';
import { emptyFunction } from '@/lib/helpers/functional';

interface Options {
  initialState?: boolean;
  onChangeState?: (v: boolean) => void;
}

const DEFAULT_OPTIONS: Required<Options> = {
  initialState: false,
  onChangeState: emptyFunction,
};

interface HookOutput {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
}

export const useIsOpenState = (
  options: Options = DEFAULT_OPTIONS,
): HookOutput => {
  const {
    onChangeState = DEFAULT_OPTIONS.onChangeState,
    initialState = DEFAULT_OPTIONS.initialState,
  } = options;

  const [isOpen, setIsOpen] = useState(initialState);

  const close = useCallback(() => {
    setIsOpen(false);
    onChangeState(false);
  }, [onChangeState]);

  const open = useCallback(() => {
    setIsOpen(true);
    onChangeState(true);
  }, [onChangeState]);

  const toggle = useCallback(() => {
    setIsOpen((prev) => {
      onChangeState(!prev);

      return !prev;
    });
  }, [onChangeState]);

  return {
    isOpen,
    close,
    open,
    toggle,
  };
};
