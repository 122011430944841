import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

export const useShouldRedirectToConsultationSurvey = () => {
  const [authUser] = useAuthUser({ ssr: false });
  const { subDomain } = useSubDomainContext();
  const surveyActiveDomains = ['ua', 'pl', 'br'];

  return (
    !authUser
    && surveyActiveDomains.includes(subDomain)
  );
};
