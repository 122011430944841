import { useEffect, useCallback } from 'react';

interface HookArguments {
  keyName: string;
  callback: () => void;
  condition?: boolean;
  stopPropagation?: boolean;
}

export const useOnKeyup = (
  args: HookArguments,
) => {
  const {
    keyName,
    callback,
    condition = true,
    stopPropagation = false,
  } = args;

  const handleEventKeyup = useCallback((event: KeyboardEvent) => {
    if (event.key === keyName) {
      callback();
    }

    if (stopPropagation) {
      event.stopPropagation();
    }
  }, [callback, keyName, stopPropagation]);

  useEffect(() => {
    if (condition) {
      window.addEventListener('keyup', handleEventKeyup);
    }

    return () => {
      window.removeEventListener('keyup', handleEventKeyup);
    };
  }, [condition, handleEventKeyup]);
};
